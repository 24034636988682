.mainSlider .swiper-pagination-bullets {
  bottom: 1.5rem;
}
.mainSlider .swiper-pagination-bullet,
.productSlider .swiper-pagination-bullet {
  background: #f6cd46;
}

.productSlider .swiper-button-prev::after {
  font-size: 2rem;
}

.productSlider .swiper-button-prev::after,
.productSlider .swiper-button-next::after {
  font-size: 2rem;
  color: #f6cd46;
  padding: 1rem;
  width: 200px;
}


.cardbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-image: conic-gradient(
    at 100% 100%,
    #f6cd46 0deg,
    #f6cd46 90deg,
    transparent 130deg,
    transparent 180deg,
    #f6cd46 180deg,
    #f6cd46 270deg,
    transparent 310deg,
    transparent 360deg
  );
  transition: opacity 1.5s;
  z-index: -1;
}

.cardbox:hover::before {
  opacity: 1;
}

.cardbox::after {
  content: "";
  color: #ddd;
  position: absolute;
  background-image: linear-gradient(to bottom, #1f2126, #33363d);
  border-radius: 5px;
  display: flex;
  font-size: 17px;
  align-items: center;
  justify-content: center;
  height: 95%;
  width: 95%;
  left: 50%;
  top: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}



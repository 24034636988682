.loading-cross {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-row {
  display: flex;
}

.loading-square {
  width: 30px;
  height: 30px;
  background-color: #000;
  margin: 5px;
  animation: loading-animation 2s infinite ease-in-out alternate;
}

.square1 {
  animation-delay: 0s;
}

.square2 {
  animation-delay: 0.5s;
}

.square3 {
  animation-delay: 1s;
}

.square4 {
  animation-delay: 1.5s;
}

@keyframes loading-animation {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(60px, 60px);
  }
}


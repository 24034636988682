

.scrolling-text-content {
  
  white-space: nowrap;
}

.scrolling-text-item {
 
  white-space: nowrap;
 
  
}
